<template>
  <LiefengContent>
    <template v-slot:title>日志管理</template>
    <template v-slot:toolsbarLeft>
    </template>
    <template v-slot:toolsbarRight>
      <div style="margin-left: 20px" class="header-wrapper">
        <template>
         <Input
            :maxlength="20"
            enter-button
            v-model.trim="searchForm.account"
            placeholder="操作账号"
            style="margin-right:10px;width: 150px"
        />
         <Input
            :maxlength="50"
            enter-button
            v-model.trim="searchForm.custGlobalId"
            placeholder="全局ID"
            style="margin-right:10px;width: 150px"
        />
         <Input
            :maxlength="20"
            enter-button
            v-model.trim="searchForm.oemCode"
            placeholder="oemCode"
            style="margin-right:10px;width: 150px"
        />
         <Input
            :maxlength="20"
            enter-button
            v-model.trim="searchForm.appCode"
            placeholder="应用编码"
            style="margin-right:10px;width: 150px"
        />
         <Input
            :maxlength="20"
            enter-button
            v-model.trim="searchForm.methodDesc"
            placeholder="方法描述"
            style="margin-right:10px;width: 150px"
        />
        <DatePicker v-model="dateTime" type="daterange" split-panels placeholder="请选择日期" @on-change="changeDate" style="width: 200px"></DatePicker>
          <Select
              placeholder="请选择状态搜索"
              v-model="searchForm.status" style="width:200px;margin-left: 10px;"
          >
            <Option value="">全部</Option>
            <Option value="0">正常</Option>
            <Option value="1">异常</Option>
          </Select>
        </template>
        <Button type="primary" @click="searchBtn">查询</Button>
        <Button type="info" @click="resetBtn">重置</Button>
      </div>

    </template>
    <template v-slot:contentArea>
        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageSize"
            @hadlePageSize="hadlePageSize"
        ></LiefengTable>
        <LiefengModal
          title="查看详情"
          :value="editModal.status"
          @input="editModalFn"
          :fullscreen="false"
          width="40%"
          class="edit"
        
        >
          <template v-slot:contentarea>
              <div class="detail-div">
                  <div class="item">
                      <div class="left">操作账户:</div>
                      <div class="right">{{detailMessage.account || '暂无'}}</div>
                  </div>
                  <div class="item">
                      <div class="left">全局ID:</div>
                      <div class="right">{{detailMessage.custGlobalId || ''}}</div>
                  </div>
                  <div class="item">
                      <div class="left">方法地址:</div>
                      <div class="right">{{detailMessage.method || ''}}</div>
                  </div>
                  <div class="item">
                      <div class="left">方法描述:</div>
                      <div class="right">{{detailMessage.methodDesc || ''}}</div>
                  </div>
                  <div class="item">
                      <div class="left">状态:</div>
                      <div class="right">{{detailMessage.status ? detailMessage.status == '0' ? '正常': detailMessage.status == '2' ? '异常':'' :'' || ''}}</div>
                  </div>
                  <div class="item">
                      <div class="left">oem编码:</div>
                      <div class="right">{{detailMessage.oemCode||''}}</div>
                  </div>
                  <div class="item">
                      <div class="left">接口请求时间:</div>
                      <div class="right">{{detailMessage.times||''}}</div>
                  </div>
                  <div class="item">
                      <div class="left">创建时间:</div>
                      <div class="right">{{detailMessage.gmtCreate ? $core.formatDate(new Date(detailMessage.gmtCreate),"yyyy-MM-dd hh:mm:ss") : '' || ''}}</div>
                  </div>
                  <div class="item">
                      <div class="left">请求路径:</div>
                      <div class="right">{{detailMessage.requestPath || ''}}</div>
                  </div>
              </div>
          </template>
          <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="cancelEdit">确定</Button>
        </template>
      </LiefengModal>

    </template>
  </LiefengContent>
</template>

<script>
//@route('/verified')
import LiefengTable from "@/components/LiefengTable";
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";

export default {
  components: {
    LiefengTable,
    LiefengContent,
    LiefengModal,
  },
  data() {
    return {
      //搜索    
      searchForm: {
          status:'',
          account:'',
          appCode:'',
          methodDesc:'',
          startTime:'',
          endTime:'',
          custGlobalId:'',
          oemCode:''

      },
      //table列表
      tableColumns: [
        {
          title: '操作账户',
          key: 'account',
          minWidth: 100,
          align: "center"
        },
        {
          title: '全局id',
          key: 'custGlobalId',
          minWidth: 100,
          align: "center"
        },
        {
          title: '方法',
          key: 'methodDesc',
          minWidth: 100,
          align: "center"
        },
        {
          title: '应用编码',
          key: 'appCode',
          minWidth: 100,
          align: "center"
        },
        {
          title: '状态',
          key: 'status',
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            return h('div',{},params.row.status ? params.row.status == '0' ? '正常' : params.row.status == '1' ? '异常':'':'')
          }
        },
        {
          title: 'oem编码',
          key: 'oemCode',
          minWidth: 100,
          align: "center"
        },
        {
          title: '接口请求时间',
          key: 'times',
          minWidth: 100,
          align: "center"
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            return h('div',{},params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate),"yyyy-MM-dd hh:mm:ss") : '')
          }
        },

        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: "center",
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
                      h(
                        "Button",
                        {
                        props: {
                            type: "info",
                            size: "small"
                        },
                        on: {
                                click: () => {
                                    this.getDetail(params.row.id)
                                }
                            }
                        },
                    "查看"
              ),
            ])
          }
        }
      ],
      tableData: [], //table的数据
      loading: false, //table 加载
      page: 1, //分页
      total: 0,   //总数量
      pageSize: 20, //每一页显示的数量


      // 修改实名认证数据
      editModal:{
        status:false,
        row:{},
        tip:false,
        dataScopeList:[]
      },
      dateTime:[],
      detailMessage:{}
    };
  },
  created() {
      this.getList()
  },
  methods: {
    //   方法部分
    // 弹窗关闭确认按钮
    cancelEdit(){
        this.editModal.status = false
    },
    // 关闭弹窗方法
    editModalFn(status){
        this.editModal.status = status
    },
    // 改变分页的方法
    hadlePageSize(val){
        this.page = val.page
        this.pageSize = val.pageSize
        this.getList()
    },
    //  改变日期的方法
    changeDate(val){
        this.searchForm.startTime = val[0]
        this.searchForm.endTime = val[1]
    },
    //   搜索按钮
    searchBtn(){
        this.page = 1
        this.getList()
    },
    //   重置按钮
    resetBtn(){
        this.searchForm = {
            status:'',
            account:'',
            appCode:'',
            methodDesc:'',
            startTime:'',
            endTime:'',
            custGlobalId:'',
            oemCode:''
        }
        this.dateTime = []
        this.page = 1
        this.getList()
    },
    //   接口部分
    getList(){
        this.loading = true
        this.$get('/dictlist/api/lfcommon/pc/log/selectLogPage',{
            custGlobalId:this.searchForm.custGlobalId,
            account:this.searchForm.account,
            appCode:this.searchForm.appCode,
            oemCode:this.searchForm.oemCode,
            methodDesc:this.searchForm.methodDesc,
            status:this.searchForm.status,
            startTime:this.searchForm.startTime,
            endTime:this.searchForm.endTime,
            page:this.page,
            pageSize:this.pageSize
        }).then(res=>{
            this.loading = false
            if(res.code == 200){
                this.tableData = res.dataList
                this.total = res.maxCount

            }else{
                this.$Message.error({
                    content:'获取列表失败',
                    background:true
                })
                return
            }
        })
    },
    getDetail(id){
        this.$Message.loading({
                content: "正在加载数据，请稍等...",
                duration: 0,
            });
        this.$get('/dictlist/api/lfcommon/pc/log/selectLogById',{
            id
        }).then(res=>{
            this.$Message.destroy();
            if(res.code == 200){
                this.editModal.status = true
                this.detailMessage = res.data
            }else{
                this.$Message.error({
                    content:'获取日志详情失败',
                    background:true
                })
                return
            }
        })
    }
    
   
  },
  
};
</script>

<style scoped lang='less'>

/deep/ #toolsbarRight {
  width: 1000%;

}

.header-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.edit {
  /deep/ #modal_contentarea{
        overflow: visible !important;
        padding-bottom: 20px;
  }
}
.export-modal{
  /deep/ #modal_contentarea{
        overflow: visible !important;
        height: 270px;
    }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.detail-div{
    .item{
        display: flex;
        margin: 10px 0;
        .left{
            width: 100px;
        }
        .right{
            flex: 1;
            
        }
    }
}
</style>


